import React from "react";
import { Link } from "gatsby";
import "./PostListing.scss";

class PostListing extends React.Component {
  getPostList() {
    const postList = [];

    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        // excerpt: postEdge.node.excerpt,
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();
    return (
      <div className="posts__list">
        {/* Your post list here. */
        postList.map(post => (
          <Link className="posts__list__link" to={post.path} key={post.title}>
            <h2 class="posts__item"><span class="arrow">→</span> {post.title}</h2>
          </Link>
        ))}
      </div>
    );
  }
}

export default PostListing;
