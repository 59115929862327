import React, { Component } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby"
import Layout from "../layout";
import Home from "../components/Home/Home";
import config from "../../data/SiteConfig";

class Index extends Component {
  
  render() {
    const postList = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <div className="home-container">
          <Helmet title={`Home | ${config.siteTitle}`} />
          <Home postListing={postList}/>
        </div>
      </Layout>
    );
  }
}

export default Index;


export const homeQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC },
      filter: {frontmatter: {category: {eq: "project"}}}
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            category
            credits {
              name
              role
            }
            cover {
              id
            }
            tags
          }
          id
          fields {
            slug
          }
        }
      }
    }
  }
`