import React, { Component } from "react";
import "./Header.scss";

class Header extends Component {
  render() {
    return (
      <header className="header">
        <h1 className="header__title">Hello. I'm Joey Lee, your friendly, neighborhood, creative technologist. <br/> I craft with kindness.</h1>
        <h2 className="header__subtitle">We can be neighbors via &nbsp;
        <a className="header__link" href="mailto:jsphknglee@gmail.com" rel="noreferrer" target="_blank">→ email</a>
        <a className="header__link" href="https://github.com/joeyklee" rel="noreferrer" target="_blank">→ github</a>
        <a className="header__link" href="https://twitter.com/leejoeyk" rel="noreferrer" target="_blank">→ twitter</a>
        </h2>
      </header>
    );
  }
}

export default Header;
