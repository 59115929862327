import React, { Component } from "react";
import "./Home.scss";
import Header from "../Header/Header";
import PostListing from "../PostListing/PostListing";

class Home extends Component {
  render() {
    return (
      <div className="home">
        <Header/>
        <main className="main">
          <h3 className="main__title">Selected Work</h3>
          <PostListing postEdges={this.props.postListing}></PostListing>
        </main>
      </div>
    );
  }
}

export default Home;
